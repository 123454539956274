











































































import { Component, Prop, Vue } from "vue-property-decorator";
import { User } from "@/types/user";
import { Company } from "@/types/user/company";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import AdminUserAddressTable from "@/components/admin/user/address/AdminUserAddressTable.vue";

@Component({
    components: {
        AdminUserAddressTable,
    },
})
export default class AdminUserCompanyForm extends Vue {
    @Prop({ type: Object }) readonly company!: Company;

    private users: User[] = [];

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        try {
            this.users = await this.fetchUsers();
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Fetches all users
     *
     * @returns Promise<User[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchUsers(): Promise<User[]> {
        const response = (await axios.get<APIResponse<User[]>>("/admin/user"))
            .data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }
}
