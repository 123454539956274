var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{attrs:{"label":_vm.$tc('language.name'),"rules":[function (v) { return !!v; }],"required":""},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}}),_c('v-text-field',{attrs:{"label":_vm.$tc('shop.article.priceClass.tax')},model:{value:(_vm.company.taxNumber),callback:function ($$v) {_vm.$set(_vm.company, "taxNumber", $$v)},expression:"company.taxNumber"}}),_c('AdminUserAddressTable',{attrs:{"editable":"","userAddresses":_vm.company.addresses},on:{"crudDialogSave":function (updatedAddresses) { return (_vm.company.addresses = updatedAddresses); }}}),_c('v-autocomplete',{attrs:{"label":_vm.$tc('user.company.employee', 100),"items":_vm.users,"item-text":function (employee) { return ((employee.firstname) + " " + (employee.lastname)); },"item-value":function (employee) { return employee; },"value-comparator":function (a, b) {
                if (Array.isArray(a) || Array.isArray(b)) { return false; }
                return a.id === b.id;
            },"multiple":"","rules":[function (v) { return !!v; }],"required":""},model:{value:(_vm.company.employees),callback:function ($$v) {_vm.$set(_vm.company, "employees", $$v)},expression:"company.employees"}}),_c('v-select',{attrs:{"label":_vm.$t('user.company.responsible'),"items":_vm.users,"item-text":function (employee) { return ((employee.firstname) + " " + (employee.lastname)); },"item-value":function (employee) { return employee.id; },"rules":[function (v) { return !!v; }],"required":""},model:{value:(_vm.company.responsibleId),callback:function ($$v) {_vm.$set(_vm.company, "responsibleId", $$v)},expression:"company.responsibleId"}}),_c('v-text-field',{attrs:{"label":_vm.$t('user.company.discount'),"type":"number","min":"0","max":"100","rules":[
            function (v) { return typeof v === 'number'; },
            function (v) { return v >= 0 ||
                _vm.$t('form.validation.minValue', { minValue: 0 }).toString(); },
            function (v) { return v <= 100 ||
                _vm.$t('form.validation.maxValue', {
                    maxValue: 100,
                }).toString(); } ],"required":""},model:{value:(_vm.company.discount),callback:function ($$v) {_vm.$set(_vm.company, "discount", _vm._n($$v))},expression:"company.discount"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }